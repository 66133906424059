import { gql } from "@apollo/client";

export const FETCH_ALL_CATEGORIES = gql`
query FetchCourseCategories {
    fetchCourseCategories {
      id
      categoryName
    }
  }
`;

export const FETCH_STORE_CATEGORIES = gql`
query Query {
  fetchStoreItemCategories {
    id
    categoryName
  }
}
`;

export const FETCH_SINGLE_CATEGORY = gql`
query FetchSingleCourseCategory($categoryId: String!) {
  fetchSingleCourseCategory(categoryId: $categoryId) {
    id
    categoryName
    courses {
      shortDescription
      price
      previewVideoUrl
      ownerId
      owner {
        username
        name
        avatar
        firstName
        lastName
        id
      }
      name
      imageUrl
      id
      courseType
      courseCategory {
        id
        categoryName
      }
    }
  }
}
`;

export const FETCH_SINGLE_STORE_ITEM_CATEGORY = gql`
  query FetchSingleStoreItemCategory($categoryId: String!) {
    fetchSingleStoreItemCategory(categoryId: $categoryId) {
      id
      categoryName
      storeItems {
        id
        description
        name
        item
        ownerId
        price
        owner {
          id
          name
          bio
          avatar
        }
        storeItemCategory {
          id
          categoryName
        }
      }
    }
}
`
