import Link from "next/link";
import Image from "next/image";

import CourseData from "../../../data/course-details/courseData.json";
import { useAppContext } from "@/context/Context";
import { useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import Spinner from "@/components/Spinner";
import { FETCH_ALL_COURSES } from "@/graphql/querries/courses";
import CourseFilterOneToggle from "@/components/Category/Filter/CourseFilterOneToggle";
import Pagination from "@/components/Common/Pagination";

const Search = () => {
  const { search, toggle } = useAppContext();
  const [input, setInput] = useState("");
  const [page, setPage] = useState(1);
  const inputRef = useRef();
  const { data: AllCourses } = useQuery(FETCH_ALL_COURSES, {
    variables: {
      paginationArgs: {
        pageSize: 6,
        pageNumber: page,
      },
    }
  })

  const courses = AllCourses?.fetchAllCourses;

  const filterItem = useMemo(() => {
    return courses?.filter(course => {
      return course.name.toLowerCase().includes(input.toLowerCase())
    })
  }, [courses, input])

  return (
    <>
      <div className={`rbt-search-dropdown ${!search ? "active" : ""}`}>
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <form action="#">
                <input type="text" placeholder="What are you looking for?"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <div className="submit-btn">
                  <Link className="rbt-btn btn-gradient btn-md" href="#">
                    Search
                  </Link>
                </div>
              </form>
            </div>
          </div>

          <div className="rbt-separator-mid">
            <hr className="rbt-separator m-0" />
          </div>

          <div className="row g-4 pt--30 pb--60">
            <div className="col-lg-12">
              <div className="section-title">
                <h5 className="rbt-title-style-2">Our Top Course</h5>
              </div>
            </div>

            <div className="mt-5">
              <div className="inner">
                <div className="container">

                  <CourseFilterOneToggle course={filterItem} />

                  <div className="row">
                    <div className="col-lg-12 mt--60">
                      <Pagination pageNumber={page} setPage={setPage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
