import Head from "next/head";

const PageHead = ({ title }) => {
  return (
    <>
      <Head>
        <title>{title ? title : "Mentorbliss — Explore new education."}</title>
        <meta
          name="description"
          content="Mentorbliss is a new education platform equipping future leaders."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link href="https://api.fontshare.com/v2/css?f[]=satoshi@300,301,400,401,500,501,700,701,900,901,1,2&display=swap" rel="stylesheet" />
      </Head>
    </>
  );
};

export default PageHead;
