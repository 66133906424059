import Image from "next/image";
import Link from "next/link";

import CourseDetails from "../../data/course-details/courseData.json";
import useIsMobile from "@/hooks/useMobile";
import styles from "./../12-Marketplace/market.module.scss"

const Card = ({ courses }) => {
  const isPhone = useIsMobile();

  const getFirstNWords = (str, numWords) => {
    const words = str.split(" ");
    const selectedWords = words.slice(0, numWords);
    return selectedWords.join(" ");
  };
  return (
    <>
      {courses?.map((data, index) => (
        <div
          className={`col-lg-4 col-md-6 col-sm-12 col-12`}
          data-sal-delay="150"
          data-sal="slide-up"
          data-sal-duration="800"
          key={index}
        >
          <div className={`d-flex flex-column justify-content-evenly align-items-center variation-01 rbt-hover ${styles["card"]}`}>
            <div className="rbt-card-img object-fit-contain w-100 flex-grow">
              <Link href={`/course-details/${data.id}`}>
                {data?.imageUrl ? (
                  <Image
                    src={data?.imageUrl}
                    classN
                    className="w-100  img-fluid"
                    width={355}
                    height={244}
                    alt="Card image"
                  />
                ) : (
                  <Image
                    src={`/images/course/course-01.jpg`}
                    width={355}
                    classN
                    className="object-fit-fill"
                    height={244}
                    alt="Card image"
                  />
                )}
              </Link>
            </div>
            <div className="bt-card-body p-3 flex-grow w-100">

              <h4 className={`fs-2 fw-bold pt-5 ${styles['subtitle']}`}>
                <Link href={`/course-details/${data.id}`}>{data?.name}</Link>
              </h4>

              {data?.owner ? (
                <div className="rbt-author-meta mb--10">
                  <div className="rbt-avater">
                    <Link href={`/profile/${data?.owner?.id}`}>
                      <Image
                        src={data.owner?.avatar}
                        width={33}
                        height={33}
                        alt="Sophia Jaymes"
                      />
                    </Link>
                  </div>
                  <div className="rbt-author-info">
                    By{" "}
                    <Link href={`/profile/${data?.owner?.id}`}>
                      {data?.owner?.name}
                    </Link>{" "}
                    In{" "}
                    <Link
                      href={`/courses/category/${data?.courseCategory?.id}`}
                    >
                      {data?.courseCategory?.categoryName}
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="d-flex justify-content-between align-items-center rbt-card-bottom">
                <div className="rbt-price">
                  <span className="current-price">CFA {data.price}</span>
                </div>
                {data.button ? (
                  <Link
                    className="rbt-btn-link left-icon"
                    href={`/course-details/${data.id}`}
                  >
                    <i className="feather-shopping-cart"></i> Add To Cart
                  </Link>
                ) : (
                  <Link
                    className="rbt-btn-link"
                    href={`/course-details/${data.id}`}
                  >
                    Learn More<i className="feather-arrow-right"></i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
