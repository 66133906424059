import React, { useState } from "react";
import { useRouter } from "next/router";

import CategoryBanner from "./Category-Banner";
import CourseFilter from "./Filter/CourseFilter";
import { useAppContext } from "@/context/Context";
import CategoryAllBanner from "./Category-All-Banner";

const CategoryHead = ({ category, courses }) => {
  const router = useRouter();
  const path = router.pathname;
  const { toggle, setToggle } = useAppContext();
  const [filterToggle, setFilterToggle] = useState(true);

  return (
    <>
      <div className="rbt-page-banner-wrapper bg-primary">
        <div className="rbt-banner-image"></div>

        <div className="rbt-banner-content">
          {category ? (
            <CategoryBanner category={category} courses={courses} />
          ) : (
            <CategoryAllBanner courses={courses} />
          )}

          <div className="rbt-course-top-wrapper mt--40 mt_sm--20">
            <div className="container">
              <div className="row g-5 align-items-center">
                <div className="col-lg-5 col-md-12">
                  <div className="rbt-sorting-list d-flex flex-wrap align-items-center">
                    <div className="rbt-short-item switch-layout-container">
                      <ul className="course-switch-layout">
                        <li className="course-switch-item">
                          <button
                            className={`rbt-grid-view ${
                              toggle ? "active" : ""
                            }`}
                            title="Grid Layout"
                            onClick={() => setToggle(!toggle)}
                          >
                            <i className="feather-grid"></i>{" "}
                            <span className="text">Grid</span>
                          </button>
                        </li>
                        <li className="course-switch-item">
                          <button
                            className={`rbt-grid-view ${
                              !toggle ? "active" : ""
                            }`}
                            title="List Layout"
                            onClick={() => setToggle(!toggle)}
                          >
                            <i className="feather-list"></i>{" "}
                            <span className="text">List</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {path === "/course-filter-one-open" ? (
                <CourseFilter />
              ) : (
                <CourseFilter filterToggle={filterToggle} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryHead;
