import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import logo from "../../../public/images/logo/logo.png";
import Logo from "../../../public/images/logo/main-logo.webp";

import Nav from "../Nav";
import Category from "../Category/Category";
import { useAppContext } from "@/context/Context";
import { useSession } from "@/hooks/useSession";

const HeaderSeven = ({
  gapSpaceBetween,
  transparent,
  navigationEnd,
  btnClass,
  btnText,
  headerType,
}) => {
  const { mobile, setMobile } = useAppContext();
  const [isSticky, setIsSticky] = useState(false);
  const { currentUser } = useSession();
  const CTAButtonLink = currentUser && currentUser?.isInstructor ? "/instructor/instructor-dashboard": "/student/student-dashboard"

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 180) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`rbt-header-wrapper ${gapSpaceBetween} ${transparent} ${
          !headerType && isSticky ? "rbt-sticky bg-white" : "bg-transparent"
        }`}
      >
        <div className="container">
          <div className={`mainbar-row ${navigationEnd} align-items-center`}>
            <div className="header-left rbt-header-content">
              <div className="header-info">
                <div className="logo">
                  <Link href="/">
                    <Image
                      src={Logo}
                      width={152}
                      height={50}
                      priority={true}
                      alt="Education Logo Images"
                    />
                  </Link>
                </div>
              </div>
              <div className="header-info d-none d-lg-block">
                <Category />
              </div>
            </div>

            <div className="rbt-main-navigation d-none d-xl-block">
              <Nav />
            </div>
            <div className="header-right">
              {currentUser ? (
                <div className="rbt-btn-wrapper d-none d-xl-block">
                  <Link
                    className={`rbt-btn ${btnClass}`}
                    href={CTAButtonLink}
                  >
                    <span data-text={`${btnText}`}>Dashboard</span>
                  </Link>
                </div>
              ) : (
                <div className="rbt-btn-wrapper d-none d-xl-block">
                  <Link
                    className={`rbt-btn ${btnClass}`}
                    href="/create-account"
                  >
                    <span data-text={`${btnText}`}>{btnText}</span>
                  </Link>
                </div>
              )}

              <div className="mobile-menu-bar d-block d-xl-none">
                <div className="hamberger">
                  <button
                    className="hamberger-button rbt-round-btn"
                    onClick={() => setMobile(!mobile)}
                  >
                    <i className="feather-menu"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSeven;
