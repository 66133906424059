import Router from "next/router";
import React, { useEffect, useState } from "react";
import { identity } from "./sdk";
import { toast } from "react-toastify";

// Returns a function which will log the user out
export function LogoutLink() {
  const [logoutToken, setLogoutToken] = useState();

  useEffect(() => {
    identity
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        setLogoutToken(data.logout_token);
      })
      .catch((err) => console.log(err));
  });

  return () => {
    if (logoutToken) {
      identity
        .updateLogoutFlow({ token: logoutToken })
        .then(() => {
          Router.push("/").then(() =>Router.reload());
        })
        .catch((error) => {
          console.error("Logout error:", error.message);
          Router.push("/");
        });
    }
  };
}

export const HandelErrorMessages = (err) => {
  if (err !== null) {
    let errMsg = err?.response?.data?.ui?.messages;
    const responseData = err.response?.data || {};

    switch (err.response?.status) {
      case 400: {
        if (responseData.error?.id == "session_already_available") {
          toast.success("You are already logged in.");
          Router.push("/student/student-dashboard");
          return Promise.resolve();
        }
        break;
      }
    }
    return errMsg;
  }
};
