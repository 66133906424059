import { gql } from "@apollo/client";

export const FETCH_TOTAL_COURSE_COUNT = gql`
  query Query {
    fetchTotalCourseCount
  }
`;

export const FETCH_ALL_USER_PURCHASED_COURSE = gql`
query FetchAllPurchasedCourse($paginationArgs: IPaginationArgs!) {
  fetchAllPurchasedCourse(paginationArgs: $paginationArgs) {
    id
    courseId
    userId
    course {
      id
      name
      imageUrl
      previewVideoUrl
      shortDescription
      owner {
        id
        avatar
        name
      }
      courseType
      courseCategory {
        categoryName
        id
      }
    }
    status
  }
}
`;
export const FETCH_ALL_COURSES = gql`
query FetchAllCourses($paginationArgs: IPaginationArgs!) {
  fetchAllCourses(paginationArgs: $paginationArgs) {
    id
    name
    shortDescription
    fullDescription
    ownerId
    price
    imageUrl
    owner {
      firstName
      email
      name
      lastName
      id
      avatar
    }
    courseCategory {
      id
      categoryName
    }
  }
}

`;

export const FETCH_SINGLE_COURSE = gql`
query FetchSingleCourse($courseId: String!) {
  fetchSingleCourse(courseId: $courseId) {
    id
    name
    imageUrl
    previewVideoUrl
    shortDescription
    fullDescription
    ownerId
    price
    courseType
    expectedOutcome
    courseRequirements
    affiliateCommission
    isAffiliationCourse
    courseCategory {
      id
      categoryName
    }
    lessons {
      id
      title
      slug
      text
      index
      videoUrl
      courseId
    }
    owner {
      id
      publicEmail
      firstName
      lastName
      name
      bio
      occupation
      avatar
      facebookUrl
      twitterUrl
      instagramUrl
      linkdinUrl
    }
  }
}
`;
export const TAKE_LESSON = gql`
query TakeLesson($courseId: String!) {
  takeLesson(courseId: $courseId) {
    id
    name
    imageUrl
    previewVideoUrl
    shortDescription
    expectedOutcome
    courseRequirements
    fullDescription
    ownerId
    price
    affiliateCommission
    isAffiliationCourse
    isForKids
    courseType
    courseCategoryId
    courseRatingId
      courseCategory {
      id
      categoryName
    }
   owner {
      id
      publicEmail
      firstName
      lastName
      name
      bio
      occupation
      avatar
      facebookUrl
      twitterUrl
      instagramUrl
      linkdinUrl
    }
  }
}
`;
export const FETCH_COURSE_LESSONS = gql`
query FetchLessons($courseId: String!) {
  fetchLessons(courseId: $courseId) {
    id
    title
    slug
    text
    index
    videoUrl
    courseId
  }
}
`;

export const PURCHASE_COURSE = gql`
query PurchaseCourse($courseId: String!) {
  purchaseCourse(courseId: $courseId) {
    message
    url
    isItemPurchased
  }
}
`;
export const FETCH_COURSE_CREATED = gql`
query FetchCreatedCourses($paginationArgs: IPaginationArgs!) {
  fetchCreatedCourses(paginationArgs: $paginationArgs) {
    id
    name
    imageUrl
    previewVideoUrl
    shortDescription
    fullDescription
    ownerId
    price
    courseType
    courseCategoryId
    courseRatingId
    owner {
      id
      lastName
      name
      avatar
    }
    courseCategory {
      id
      categoryName
    }
  }
}
`;
