import Link from "next/link";
import Image from "next/image";

import { useState } from "react";

import MenuData from "../../data/MegaMenu";
import CourseFilerOneOpenLayout from '@/pages/courses'
import CourseLayout from "./NavProps/CourseLayout";
import PageLayout from "./NavProps/PageLayout";
import ElementsLayout from "./NavProps/ElementsLayout";

import addImage from "../../public/images/service/mobile-cat.jpg";

const Nav = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
  };

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            className={`${activeMenuItem === "/pages/marketplace" ? "open" : ""}`}
            onClick={() => toggleMenuItem("/pages")}
            href="/"
          >
            Home
          </Link>
        </li>

        <li className="with-megamenu has-menu-child-item">
          <Link
            className={`${activeMenuItem === "/pages/course-filter-one-open" ? "open" : ""}`}
            href="/courses"
            onClick={() => toggleMenuItem("/pages/course-filter-one-open")}
          >
            Courses
          </Link>
        </li>

        <li className="with-megamenu has-menu-child-item">
          <Link
            className={``}
            href="/kids-teens"
            onClick={() => toggleMenuItem("/pages/kids-teens")}
          >
            Kids & Teens
          </Link>
        </li>

        {/* <li className="">
          <Link
            className={``}
            href="/kids-teens"
          >
            Kids & Teen
          </Link>
        </li> */}
        <li className="">
          <Link
            className={``}
            href="https://t.me/Mentorbliss"
          >
            Join Our Community
          </Link>
        </li>
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            className={``}
            href="/market-place"
            onClick={() => toggleMenuItem("/pages/market-place")}
          >
            Marketplace
          </Link>
        </li>
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            className={``}
            href="/events"
            onClick={() => toggleMenuItem("/pages/events")}
          >
            Events
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Nav;
