import React, { useCallback, useEffect, useState } from "react";
import { SetUriFlow } from "@/pkg/ory/helpers";
import { HandelErrorMessages } from "@/pkg/ory/hooks";
import Router, { useRouter } from "next/router";
import { identity } from "@/pkg/ory/sdk";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "@/graphql/querries/user";

export const useSession = () => {
  // Get the current user session
  const [session, setSession] = useState();

  const router = useRouter();

  // Fetch the current user
  const { data } = useQuery(GET_CURRENT_USER);

  let isVerified = session?.identity?.verifiable_addresses?.find(
    (id) => id?.verified === false
  );

  useEffect(() => {
    // If the router is not ready yet, or we already have a session, do nothing.
    identity
      .toSession()
      .then(({ data: session }) => {
        // we set the session data which contains the user Identifier and other traits.
        setSession(session);
      })
      .catch((error) => {
        // Handle all other errors like error.message "network error" if Kratos can not be connected etc.
        const errorMessage = HandelErrorMessages(error);
      });
  }, [router.isReady]);

  return {
    session: session?.identity?.traits,
    isVerified,
    currentUser: data?.currentUser
  };
};
