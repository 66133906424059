import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
query CurrentUser {
  currentUser {
    id
    email
    publicEmail
    firstName
    lastName
    name
    username
    phone
    bio
    occupation
    profileHeaderImage
    gender
    referrerCode
    avatar
    facebookUrl
    twitterUrl
    instagramUrl
    linkdinUrl
    appliedToBeInstructor
    isInstructor
  }
}
`;

export const GET_USER_PUBLIC_PROFILE = gql`
query GetUserPublicProfile($userId: String!) {
  getUserPublicProfile(userId: $userId) {
    lastName
    firstName
    avatar
    facebookUrl
    bio
    phone
    profileHeaderImage
    linkdinUrl
    twitterUrl
    instagramUrl
  }
}
`;

export const GET_USER_WALLET_INFO = gql`
query GetUserWalletInfo {
  getUserWalletInfo {
    id
    balance
    currency
    totalWithdrawal
    totalCredit
    userId

  }
}
`;

export const GET_USER_WALLET_TRANSACTIONS = gql`
query GetTransactionHistory($paginationArgs: IPaginationArgs!) {
  getTransactionHistory(paginationArgs: $paginationArgs) {
    amount
    description
    status
  }
}
`;

export const GET_INSTRUCTOR_COURSE_INFO = gql`
query GetInstructorCourseInfo {
  getInstructorCourseInfo {
    coursesOwned
    coursesEnrolled
    totalEarned
  }
}
`;
export const GET_USER_COURSE_INFO = gql`
query GetUserCourseInfo {
  getUserCourseInfo {
    coursesEnrolled

  }
}
`;
export const GET_USER_AFFILIATE_INFO = gql`
query GetAffiliateInfo($userId: String!) {
  getAffiliateInfo(userId: $userId) {
    id
    affiliateCode
    isApproved
    userId
  }
}
`;
export const GET_AFFILIATE_WALLET_INFO = gql`
query GetAffiliateWalletInfo {
  getAffiliateWalletInfo {
    id
    balance
    currency
    totalWithdrawal
    totalCredit
    userId
  }
}
`;
