import { FETCH_TOTAL_COURSE_COUNT } from "@/graphql/querries/courses";
import { useQuery } from "@apollo/client";
import Link from "next/link";
import { usePathname } from "next/navigation";

const CategoryAllBanner = ({ courses, category }) => {

  const {data} = useQuery(FETCH_TOTAL_COURSE_COUNT)

  const courseLength = data?.fetchTotalCourseCount;

  const pathname = usePathname()

  console.log(courseLength);
  return (
    <>
      <div className="rbt-banner-content-top">
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="page-list">
                  <li className="rbt-breadcrumb-item text-white">
                    <Link className="text-white" href="/">Home</Link>
                  </li>
                  <li>
                    <div className="icon-right">
                      <i className="feather-chevron-right text-white"></i>
                    </div>
                  </li>
                  <li className="rbt-breadcrumb-item active text-white">
                   { pathname === "/kids-teens" ? "Kids & Teens" : "All Courses"}
                  </li>
                </ul>
                <div className=" title-wrapper">
                 { pathname === "/kids-teens" ?  <h1 className="title mb--0 text-white">{category ? category?.categoryName :"kids & Teens"}</h1> :  <h1 className="title mb--0 text-white">{category ? category?.categoryName :"All Courses"}</h1> }
                  {/* <Link href="#" className="rbt-badge-2">
                    <div className="image">🎉</div>
                    {courseLength} Courses
                  </Link> */}
                </div>
             { pathname !== "/kids-teens" ?    <p className="description text-white">
                 {"Access hands-on Tutorials from dedicated Mentors Across the World"}
                </p> :    <p className="description text-white">
                 {"Prepare Your Children for a Future of Wealth, Greatness & Success."}
                </p> }
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default CategoryAllBanner;
