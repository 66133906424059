import Link from "next/link";

import { useSelector } from "react-redux";

import User from "../Offcanvas/User";
import { useAppContext } from "@/context/Context";
import { useSession } from "@/hooks/useSession";

const HeaderRightTwo = ({ btnClass, btnText, userType }) => {
  const { mobile, setMobile, search, setSearch, cartToggle, setCart } =
    useAppContext();

  const { total_items } = useSelector((state) => state.Cart);
  const { currentUser } = useSession();
  const CTAButtonLink = currentUser && currentUser?.isInstructor ? "/instructor/instructor-dashboard": "/student/student-dashboard"


  return (
    <div className="header-right">
      <ul className="quick-access">
        <li className="access-icon">
          <Link
            className={`search-trigger-active rbt-round-btn ${
              search ? "" : "open"
            }`}
            href="#"
            onClick={() => setSearch(!search)}
          >
            <i className="feather-search"></i>
          </Link>
        </li>

        {/* <li className="access-icon rbt-mini-cart">
          <Link
            className="rbt-cart-sidenav-activation rbt-round-btn"
            href="#cart"
            onClick={() => setCart(!cartToggle)}
          >
            <i className="feather-shopping-cart"></i>
            <span className="rbt-cart-count">{total_items}</span>
          </Link>
        </li> */}

        <li className="account-access rbt-user-wrapper d-none d-xl-block">
          <Link href={CTAButtonLink}>
            <i className="feather-user"></i>
            {currentUser ? currentUser?.firstName : null}
          </Link>
          {currentUser ?   <User currentUser={currentUser} /> : null}
        </li>

        <li className="access-icon rbt-user-wrapper d-block d-xl-none">
          <Link className="rbt-round-btn" href="#">
            <i className="feather-user"></i>
          </Link>
       {currentUser ?   <User currentUser={currentUser} /> : null}
        </li>
      </ul>

      {currentUser ? (
        <div className="rbt-btn-wrapper d-none d-xl-block">
          <Link
            className={`rbt-btn btn-sm rounded-1`}
            href={CTAButtonLink}
          >
            <span data-text={`${btnText}`}>Dashboard</span>
          </Link>
        </div>
      ) : (
        <div className="rbt-btn-wrapper d-none d-xl-block">
          <Link className={`rbt-btn btn-sm`} href="/create-account">
            <span data-text={`${btnText}`}>{btnText}</span>
          </Link>
        </div>
      )}

      <div className="mobile-menu-bar d-block d-xl-none">
        <div className="hamberger">
          <button
            className="hamberger-button rbt-round-btn"
            onClick={() => setMobile(!mobile)}
          >
            <i className="feather-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderRightTwo;
