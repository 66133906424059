import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { redirect, useRouter } from "next/navigation";

import { useAppContext } from "@/context/Context";

import Pagination from "@/components/Common/Pagination";
import Card from "@/components/Cards/Card";
import styles from "../../12-Marketplace/market.module.scss"

const CourseFilterOneToggle = ({ course }) => {
  const { toggle } = useAppContext();

  const router = useRouter();

  return (
    <>
      <div
        className={`rbt-course-grid-column`}
      >
        {/* <div className="row g-5">

        <Card courses={course} />
        </div> */}

        {course?.map((data, index) => (
          <div className="course-grid-3" key={index}>
            <div
              className={`d-flex flex-column justify-content-evenly align-items-center variation-01 rbt-hover ${styles["card"]}`}
            >
              <div className="rbt-card-img object-fit-contain  w-100 flex-grow">
                <Link href={`/course-details/${data?.id}`}>
                  {data?.imageUrl ? (
                    <Image
                      src={data?.imageUrl}
                      className="img-fluid"
                      width={555}
                      height={244}
                      alt="Card image"
                    />
                  ) : (
                    <Image
                      src={`/images/course/course-01.jpg`}
                      width={555}
                      height={244}
                      alt="Card image"
                    />
                  )}
                </Link>
              </div>
              <div className="rbt-card-body p-3 flex-grow w-100">

                <h4 className={`fs-2 fw-bold pt-5 ${styles['subtitle']}`}>
                  <Link href={`/course-details/${data?.id}`}>{data?.name}</Link>
                </h4>

                <div className="rbt-author-meta mb--10">
                  <div className="rbt-avater">
                    <Link href="#">
                      <Image
                        src={data?.owner?.avatar}
                        width={33}
                        height={33}
                        alt={data?.owner?.name}
                      />
                    </Link>
                  </div>
                  <div className="rbt-author-info">
                    By{" "}
                    <Link href={`/profile/${data?.owner?.id}`}>
                      {data?.owner?.name}
                    </Link>{" "}
                    In{" "}
                    <Link
                      href={`/courses/category/${data?.courseCategory?.id}`}
                    >
                      {data?.courseCategory?.categoryName}
                    </Link>
                  </div>
                </div>
                <div className="rbt-card-bottom">
                  <div className="rbt-price">
                    <span className="current-price">CFA {data.price}</span>
                    {/* <span className="off-price">${data.offPrice}</span> */}
                  </div>
                  <Link
                    className="rbt-btn-link"
                    href={`/course-details/${data.id}`}
                  >
                    Learn More<i className="feather-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CourseFilterOneToggle;
