import { Configuration, FrontendApi } from "@ory/client"
import axiosFactory from "axios"

/**
 * We use a custom axios instance here to add custom error messages on the response.
 * This is not required, but it helps debug issues with the Ory SDK and Ory CLI tunnel.
 * Do not use this in production! Instead, handle the errors according to your application business logic!
 */
const axios = axiosFactory.create({
  withCredentials: true,
})

// axios.interceptors.response.use(
//   (v) => Promise.resolve(v),
//   (error) => {
//     if (!error.config || !error.response) {
//       console.debug(`Axios Intercepted Error ${JSON.stringify(error.response)}`)

      // it's a network error
//       return Promise.reject({
//         response: {
//           status: 0,
//           message:
//             "Network error - this could be related to CORS or an incorrect URL set on the `NEXT_PUBLIC_ORY_SDK_URL` environment variable. ",
//         },
//       })
//     }

//     return Promise.reject(error)
//   },
// )


const identity = new FrontendApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_ORY_SDK_URL || "",
  }),
  "",
  axios,
)

export { identity }
