import Link from "next/link";
import CategoryData from "../../../data/elements/category";
import SingleCategory from "./CategoryProps/SingleCategory";
import { FETCH_ALL_CATEGORIES } from "@/graphql/querries/categories";
import { useQuery } from "@apollo/client";

const Category = () => {
  const { data } = useQuery(FETCH_ALL_CATEGORIES);
  const categories = data?.fetchCourseCategories;
  return (
    <div className="rbt-category-menu-wrapper rbt-category-update">
      <div className="rbt-category-btn">
        <div className="rbt-offcanvas-trigger md-size icon">
          <span className="d-none d-xl-block">
            <i className="feather-grid"></i>
          </span>
          <i title="Category" className="feather-grid d-block d-xl-none"></i>
        </div>
        <span className="category-text d-none d-xl-block">Category</span>
      </div>

      <div className="update-category-dropdown">
        <div className="inner">
          <ul className="dropdown-parent-wrapper">
            {categories?.map((category, i) => (
              <li key={i} className="dropdown-parent-list">
                <Link href={`/courses/category/${category?.id}`}>
                  {category?.categoryName}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Category;
