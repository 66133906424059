import {
  addToCart,
  cartReq,
  cartReqOut,
  deleteCartProduct,
  setCartError,
  toggleCartAmount,
} from "../reducer/CartReducer";

// -----------------> ADD_TO_CART
export const addToCartAction = (id, amount, product) => async (dispatch) => {
  try {
    dispatch(cartReq());

    dispatch(addToCart({ id, amount, product }));

    dispatch(cartReqOut());
  } catch (error) {
    console.log(error.response);
    dispatch(setCartError());
  }
};

// -----------------> Inc/Dec Cart Amount
export const toggleAmount = (id, value) => async (dispatch) => {
  try {
    dispatch(cartReq());

    dispatch(toggleCartAmount({ id, value }));

    dispatch(cartReqOut());
  } catch (error) {
    dispatch(setCartError());
  }
};

// -----------------> Delete_Product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch(cartReq());

    dispatch(deleteCartProduct(id));

    dispatch(cartReqOut());
  } catch (error) {
    dispatch(setCartError());
  }
};
