"use client"

import React, { useEffect, useState } from "react";

import CourseDetails from "../../data/course-details/courseData.json";

import PageHead from "../Head";
import { Provider } from "react-redux";
import Context from "@/context/Context";
import Store from "@/redux/store";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";
import Separator from "@/components/Common/Separator";
import FooterOne from "@/components/Footer/Footer-One";
import CategoryHead from "@/components/Category/CategoryHead";
import CourseFilterOneToggle from "@/components/Category/Filter/CourseFilterOneToggle";
import Pagination from "@/components/Common/Pagination";
import { useQuery } from "@apollo/client";
import { FETCH_ALL_COURSES } from "@/graphql/querries/courses";
import Spinner from "@/components/Spinner";
import { redirect, useRouter } from "next/navigation";
import HeaderStyleNine from "@/components/Header/HeaderStyle-Nine";

const CourseFilerOneOpenLayout = () => {
  const [courses, setCourse] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { data: AllCourses, loading } = useQuery(FETCH_ALL_COURSES, {
    variables: {
      paginationArgs: {
        pageSize: 6,
        pageNumber: page,
      },
    },
  });

  return (
    <>
      <PageHead title="Courses - Online Courses" />

      <HeaderStyleTen headerSticky="rbt-sticky" headerType={true} />
      <MobileMenu />
      <Cart />

      <CategoryHead courses={AllCourses?.fetchAllCourses} />
      <div className="rbt-section-overlayping-top rbt-section-gapBottom">
        <div className="inner">
          <div className="container">
            {loading ? (
              <Spinner />
            ) : (
              <CourseFilterOneToggle course={AllCourses?.fetchAllCourses} />
            )}

            <div className="row">
              <div className="col-lg-12 mt--60">
                <Pagination pageNumber={page} setPage={setPage} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />
      <FooterOne />
    </>
  );
};

export default CourseFilerOneOpenLayout;
