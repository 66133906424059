import { useQuery } from "@apollo/client";
import Link from "next/link";

const CategoryBanner = ({ courses, category }) => {

  // const {} = useQuery()

  console.log(courses);
  return (
    <>
      <div className="rbt-banner-content-top">
        <div className="container">
          {courses && (
            <div className="row">
              <div className="col-lg-12">
                <ul className="page-list">
                  <li className="rbt-breadcrumb-item text-white">
                    <Link className="text-white" href="/">Home</Link>
                  </li>
                  <li>
                    <div className="icon-right">
                      <i className="feather-chevron-right text-white"></i>
                    </div>
                  </li>
                  <li className="rbt-breadcrumb-item active text-white">
                    {category ? category?.categoryName :"All Courses"}
                  </li>
                </ul>
                <div className=" title-wrapper">
                  <h1 className="title mb--0 text-white">{category ? category?.categoryName :"All Courses"}</h1>
                  <Link href="#" className="rbt-badge-2">
                    <div className="image">🎉</div>
                    {courses?.length} Courses
                  </Link>
                </div>
                <p className="description text-white">
                  {"Access hands-on Tutorials from dedicated Mentors Across the World"}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryBanner;
